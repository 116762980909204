// tracking.js
import { useRouter } from 'vue-router'
import { useGtag } from 'vue-gtag-next'

export function useAnalytics() {
    const { event } = useGtag()
    const router = useRouter()

    function trackEvent(eventName, eventParams = {}) {
        try {
            let currentPath = router.currentRoute.value.path.slice(1).replaceAll(/\//g, '_')
            if (currentPath === '') {
                currentPath = 'home'
            }

            event(`${currentPath}_${eventName}`, {
                ...eventParams,
                page_path: currentPath,
            })
        } catch (error) {
            console.error('Error tracking event', error)
        }
    }

    return { trackEvent }
}
